@mixin brand-font-family {
    font-family: 'Pacifico', cursive;
}

@mixin uppercase-header() {
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
}

@mixin no-select() {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}