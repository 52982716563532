$sizes: 60;

@mixin padding-classes {
    @for $i from 1 through $sizes {
        $padding: $i * 1px;

        .p-#{$i}, .padding-#{$i}  { padding: $padding; }
        .pl-#{$i}, .padding-left-#{$i} { padding-left: $padding; }
        .pr-#{$i}, .padding-right-#{$i} { padding-right: $padding; }
        .pt-#{$i}, .padding-top-#{$i} { padding-top: $padding; }
        .pb-#{$i}, .padding-bottom-#{$i} { padding-bottom: $padding; }
    }
}

@include padding-classes;

@mixin no-padding {
    padding: 0;
}