$values: 2000;

@mixin min-width {
    @for $i from 1 through $values {
        $minWidth: $i * 1px;

        .min-width-#{$i} { min-width: $minWidth; }
    }
}

@include min-width;

@mixin max-width {
    @for $i from 1 through $values {
        $maxWidth: $i * 1px;

        .max-width-#{$i} { max-width: $maxWidth; }
    }
}

@include max-width;

@mixin width {
    @for $i from 1 through $values {
        $width: $i * 1px;

        .width-#{$i} { max-width: $width; min-width: $width; width: $width; }
    }
}

@include width;