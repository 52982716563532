@mixin display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.display-flex {
    @include display-flex();
}

@mixin flex() {
    flex: 1 1 1px;
    box-sizing: border-box;
}

.flex {
    @include flex();
}

@mixin flex-nogrow() {
    flex: 0 1 auto;
    box-sizing: border-box;
}

.flex-nogrow {
    @include flex-nogrow();
}

@mixin flex-none() {
    flex: 0 0 auto;
    box-sizing: border-box;
}

.flex-none {
    @include flex-none();
}

@mixin layout-wrap() {
    flex-wrap: wrap;
}

.layout-wrap {
    @include layout-wrap();
}

@mixin layout-row() {
    @include display-flex();
    box-sizing: border-box;
    flex-direction: row;
}

.layout-row {
    @include layout-row();
}

@mixin layout-column() {
    @include display-flex();
    box-sizing: border-box;
    flex-direction: column;
}

.layout-column {
    @include layout-column();
}

@mixin layout-align-center-center() {
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-center-center {
    @include layout-align-center-center();
}

@mixin layout-align-start-center() {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-start-center {
    @include layout-align-start-center();
}

@mixin layout-align-end-center() {
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-end-center {
    @include layout-align-end-center();
}

@mixin layout-align-center-end() {
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-center-end {
    @include layout-align-center-end();
}


@mixin layout-align-end-stretch() {
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
}

.layout-align-end-stretch {
    @include layout-align-end-stretch();
}

@mixin layout-align-start-stretch() {
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}

.layout-align-start-stretch {
    @include layout-align-start-stretch();
}

@mixin layout-align-center-stretch() {
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}

.layout-align-center-stretch {
    @include layout-align-center-stretch();
}


@mixin layout-align-space-between-center() {
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-space-between-center {
    @include layout-align-space-between-center();
}

@mixin layout-align-space-between-stretch() {
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

.layout-align-space-between-stretch {
    @include layout-align-space-between-stretch();
}

@mixin layout-align-space-around-center() {
    justify-content: space-around;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-space-around-center {
    @include layout-align-space-around-center();
}

@mixin layout-align-space-evenly-center() {
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    max-width: 100%;

    > * {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.layout-align-space-evenly-center {
    @include layout-align-space-evenly-center();
}