@import 'mixins/_flex';

html {
    @include layout-column();
    @include flex();
    @extend %appDefaultFont;

    max-height: 100vh;
    min-height: 100vh;
    height: 100vh;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    background: transparent;
    padding: 0;
    margin: 0;
    overflow: hidden;

    body {
        @include layout-column();
        @include flex();
        max-height: 100vh;
        min-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        min-width: 100vw;
        width: 100vw;
        background: transparent;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    @extend %appDefaultFont;

    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 16px;
    font-size: 1vh;
}