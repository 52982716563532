$values: 2000;

@mixin min-height {
    @for $i from 1 through $values {
        $minHeight: $i * 1px;

        .min-height-#{$i} { min-height: $minHeight; }
    }
}

@include min-height;

@mixin max-height {
    @for $i from 1 through $values {
        $maxHeight: $i * 1px;

        .max-height-#{$i} { max-height: $maxHeight; }
    }
}

@include max-height;
