@import './variables/_all.scss';
@import './_buttons.scss';
@import './_inputs.scss';
@import './_resets.scss';
@import './_typography.scss';

// mixins
@import './mixins/_flex.scss';
@import './mixins/_fonts.scss';
@import './mixins/_general.scss';
@import './mixins/_heights.scss';
@import './mixins/_margins.scss';
@import './mixins/_opacity.scss';
@import './mixins/_paddings.scss';
@import './mixins/_panels.scss';
@import './mixins/_widths.scss';

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.877);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.486);
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.877);
    border-radius: 20px;
    border: 3px solid rgba(0, 0, 0, 0.877);
}