@import 'mixins/fonts';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import '~@fortawesome/fontawesome-pro/css/all.min.css';

$appDefaultFontSize: 12px;
$appDefaultFontLineHeight: 20px;
$appDefaultFontWeight: 400;
$appDefaultFontColor: #1a1f36;
$appDefaultFontFamily: 'Ubuntu',
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;

%appDefaultBrandFont {
    line-height: 40px;
    font-weight: 500;
    // font-size: 1.5rem;
    color: #222;
}

%appDefaultFont {
    line-height: $appDefaultFontLineHeight;
    font-weight: $appDefaultFontWeight;
    font-size: $appDefaultFontSize;
    color: $appDefaultFontColor;
    font-family: $appDefaultFontFamily;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header {
    @include flex();
    @include layout-row();
    text-transform: uppercase;
    font-weight: 500;
    opacity: .4;
    color: #fff;

    &:not(:last-child) {
        margin-right: 10px;
    }
}

.content-header {
    @include layout-row();
    font-weight: 600;
}

.error {
    color: red;
}

.faded {
    opacity: .5;
}