$values: 100;

@mixin opacity-classes {
    @for $i from 1 through $values {
        $opacity: $i * 0.01;

        .o-#{$i}, .opacity-#{$i}  { opacity: $opacity; }
    }
}

@include opacity-classes;