@import 'mixins/_flex';



input {
    padding: 4px 10px;
    background: #090909;
    border: 1px solid #434343;
    border-radius: 4px;
    color: #fff;
    outline: 0;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &:focus {
        border: 1px solid #3dbf7d;
        background: #141414;
    }
}