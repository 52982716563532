@import 'mixins/fonts';

button {
    @include no-select();
    font: inherit;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 0;
    border-radius: 4px;
    background-color: #efefef;
    transition: all 120ms ease;
    outline: 0;
    padding: 2px 10px;

    &:disabled,
    .disabled {
        opacity: .4;

        &:hover {
            cursor: default;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: #e7e7e7;
        transition: background-color 120ms ease;
    }

    &:active {
        background-color: #dfdfdf;
        transition: background-color 120ms ease;
    }

    &.transparent {
        background-color: transparent;
        border: 0;
        color: #000;
        opacity: .6;

        &:hover {
            background-color: transparent;
            border: 0;
            opacity: 1;
        }
    }

    &.mint {
        background-color: #29ff92;
        color: #000;

        &:hover {
            background-color: #3dbf7d;
        }
    }

    &.red {
        background-color: #ff2929;
        color: #fff;

        &:hover {
            background-color: #bd2727;
        }
    }
    
    &.cta {
        background-color: #222222;
        color: #fff;
        border-bottom: 2px solid transparent;

        i {
            color: #fff;
        }

        &.active-bottom {
            border-bottom: 2px solid #3dbf7d;
        }

        &:hover {
            background-color: #3dbf7d;
        }
    }

    &.discord {
        background: #7289da;
        color: #fff;

        i {
            color: #fff;
        }

        &:hover {
            background: #bbcaff;
            color: #fff;
            box-shadow: 0px 6px 36px -9px #bbcaff;
        }
    }
}