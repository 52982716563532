$sizes: 60;

@mixin margin-classes {
    @for $i from 1 through $sizes {
        $margin: $i * 1px;

        .m-#{$i}, .margin-#{$i}  { margin: $margin; }
        .ml-#{$i}, .margin-left-#{$i} { margin-left: $margin; }
        .mr-#{$i}, .margin-right-#{$i} { margin-right: $margin; }
        .mt-#{$i}, .margin-top-#{$i} { margin-top: $margin; }
        .mb-#{$i}, .margin-bottom-#{$i} { margin-bottom: $margin; }
    }
}

@include margin-classes;

@mixin no-margin {
    margin: 0;
}